import { ConnectedPosition, OverlayModule } from '@angular/cdk/overlay';
import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  output,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { map, Observable } from 'rxjs';

import {
  ExternalAccount,
  ExternalAccountStatus,
} from '@conversations/workspaces/state/workspaces/workspaces-state.model';
import { WorkspacesSelectors } from '@conversations/workspaces/state/workspaces/workspaces.selectors';
import { getOverlayVisibilityAfterOutsideClick } from '@core/helpers/get-overlay-visibility-after-outside-click';
import { UserAvatarComponent } from '@design/misc/user-avatar/user-logo.component';
import { DropdownActionComponent } from '@design/overlays/dropdown/dropdown-action/dropdown-action.component';
import { DropdownComponent } from '@design/overlays/dropdown/dropdown.component';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';

@Component({
  selector: 'cc-composer-sender-picker',
  standalone: true,
  templateUrl: './composer-sender-picker.component.html',
  styleUrls: ['./composer-sender-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    DropdownComponent,
    DropdownActionComponent,
    OverlayModule,
    TooltipDirective,
    UserAvatarComponent,
  ],
})
export class ComposerSenderPickerComponent implements OnInit, OnChanges {
  @Input()
  senderAccountId: number;

  @Input()
  disabled: boolean;

  senderChange = output<ExternalAccount>();

  activeExternalAccount$: Observable<ExternalAccount>;
  externalAccounts$: Observable<ExternalAccount[]>;

  protected pickerDropdownVisible = false;
  protected readonly pickerDropdownPositionStrategy: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
      offsetY: 4,
    },
  ];
  protected readonly getOverlayVisibilityAfterOutsideClick = getOverlayVisibilityAfterOutsideClick;
  protected readonly ExternalAccountStatus = ExternalAccountStatus;
  private readonly store = inject(Store);

  ngOnInit(): void {
    this.getActiveSenderAccount();
    this.externalAccounts$ = this.store
      .select(WorkspacesSelectors.externalAccounts)
      .pipe(map((accounts) => accounts.filter((account) => account.status === ExternalAccountStatus.Active)));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['senderAccountId']) this.getActiveSenderAccount();
  }

  selectSender(account: ExternalAccount): void {
    if (account.status !== ExternalAccountStatus.Active) return;

    this.senderChange.emit(account);
    this.pickerDropdownVisible = false;
  }

  private getActiveSenderAccount(): void {
    this.activeExternalAccount$ = this.store.select(WorkspacesSelectors.externalAccountById(this.senderAccountId));
  }
}

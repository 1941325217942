import { ChangeDetectionStrategy, Component, Input, output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { type CompactMessage } from '@conversations/conversation/state/conversation/conversation-state.model';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { UserAvatarComponent } from '@design/misc/user-avatar/user-logo.component';
import { TooltipAlignment } from '@design/overlays/tooltip/tooltip';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';

@Component({
  selector: 'cc-composer-reply-to-message-preview',
  standalone: true,
  templateUrl: './composer-reply-to-message-preview.component.html',
  styleUrls: ['./composer-reply-to-message-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonComponent, TooltipDirective, TranslateModule, UserAvatarComponent],
})
export class ComposerReplyToMessagePreviewComponent {
  @Input()
  replyToMessage: CompactMessage;

  jumpToReplyToMessage = output<void>();

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;
  protected readonly TooltipAlignment = TooltipAlignment;
}

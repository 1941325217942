<div
  #dropdownTrigger
  (click)="dropdownVisible = !dropdownVisible"
  [class.composer-recipients-selector-contact-chip--invalid]="!emailValid"
  class="composer-recipients-selector-contact-chip"
>
  <div class="composer-recipients-selector-contact-chip__name">
    {{ contact.name?.trim() || contact.email }}
  </div>

  <i
    [class.composer-recipients-selector-contact-chip__toggle--active]="dropdownVisible"
    class="composer-recipients-selector-contact-chip__toggle icon-chevron-down-small"
  ></i>
</div>

<ng-template
  (detach)="dropdownVisible = false"
  (overlayOutsideClick)="dropdownVisible = getOverlayVisibilityAfterOutsideClick($event, dropdownTrigger)"
  [cdkConnectedOverlayOpen]="dropdownVisible"
  [cdkConnectedOverlayOrigin]="dropdownTrigger"
  [cdkConnectedOverlayPositions]="dropdownPositionStrategy"
  cdkConnectedOverlay
>
  <cc-dropdown
    class="cc-recipients-selector-dropdown"
    width="220px"
  >
    @if (contact.name && contact.email) {
      <div class="contact-details">
        <cc-user-avatar
          class="contact-details__avatar"
          [src]="contact.avatarUrl"
          [name]="contact.name || contact.email"
          [id]="contact.id"
          [size]="32"
        />

        <div class="contact-details__info">
          <div class="contact-details__info-name">{{ contact.name }}</div>
          <div class="contact-details__info-email">{{ contact.email }}</div>
        </div>
      </div>
      <cc-dropdown-action
        (click)="dropdownVisible = false"
        leadingIcon="icon-user-circle"
      >
        <ng-container slot="title">
          {{ 'conversations-v4.composer.recipientSelector.contactChip.viewContactDetails' | translate }}
        </ng-container>
      </cc-dropdown-action>

      <cc-dropdown-divider />
    }

    @if (contactType() === 'email') {
      <cc-dropdown-action
        (click)="edit.emit(); dropdownVisible = false"
        leadingIcon="icon-edit-pencil"
      >
        <ng-container slot="title">
          {{ 'conversations-v4.composer.recipientSelector.contactChip.editAddress' | translate }}
        </ng-container>
      </cc-dropdown-action>
    }

    <cc-dropdown-action
      (click)="copyEmailToClipboard(); dropdownVisible = false"
      leadingIcon="icon-copy"
    >
      <ng-container slot="title">
        {{ 'conversations-v4.composer.recipientSelector.contactChip.copyAddress' | translate }}
      </ng-container>
    </cc-dropdown-action>

    @if (contactType() === 'email') {
      <cc-dropdown-action
        (click)="remove.emit(); dropdownVisible = false"
        leadingIcon="icon-remove-attachment"
      >
        <ng-container slot="title">
          {{ 'conversations-v4.composer.recipientSelector.contactChip.removeAddress' | translate }}
        </ng-container>
      </cc-dropdown-action>
    }
  </cc-dropdown>
</ng-template>

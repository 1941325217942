import {
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  effect,
  ElementRef,
  inject,
  Injector,
  input,
  OnDestroy,
  untracked,
} from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { Editor } from '@tiptap/core';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs';

import { withBold } from '@design/forms/rich-text-editor/extensions/bold/bold.extension';
import { withBulletList } from '@design/forms/rich-text-editor/extensions/bullet-list/bullet-list.extension';
import { createExtensionKit } from '@design/forms/rich-text-editor/extensions/create-extension-kit';
import { withFontFamily } from '@design/forms/rich-text-editor/extensions/font-family/font-family.extension';
import { withFontSize } from '@design/forms/rich-text-editor/extensions/font-size/font-size.extension';
import { withItalic } from '@design/forms/rich-text-editor/extensions/italic/italic.extension';
import { withLink } from '@design/forms/rich-text-editor/extensions/link/link.extension';
import { withOrderedList } from '@design/forms/rich-text-editor/extensions/ordered-list/ordered-list.extension';
import { withPlaceholder } from '@design/forms/rich-text-editor/extensions/placeholder/placeholder.extension';
import { withStrike } from '@design/forms/rich-text-editor/extensions/strike/strike.extension';
import { withTable } from '@design/forms/rich-text-editor/extensions/table/table.extension';
import { withTaskAutocomplete } from '@design/forms/rich-text-editor/extensions/task-autocomplete/task-autocomplete.extension';
import { withUnderline } from '@design/forms/rich-text-editor/extensions/underline/underline.extension';
import { RichTextEditorComponent } from '@design/forms/rich-text-editor/rich-text-editor.component';

import type { ComposerInstance } from '../state/composers/composers-state.model';
import { SaveDraft, SetMessage } from '../state/composers/composers.actions';

@Component({
  selector: 'cc-composer-message-input',
  standalone: true,
  templateUrl: './composer-message-input.component.html',
  styleUrls: ['./composer-message-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RichTextEditorComponent, ReactiveFormsModule],
})
export class ComposerMessageInputComponent implements OnDestroy {
  composer = input.required<ComposerInstance>();
  message = input<string>('');

  private readonly injector = inject(Injector);
  private readonly destroyRef = inject(DestroyRef);
  private readonly store = inject(Store);
  private readonly elementRef = inject(ElementRef);

  protected readonly composerId = computed(() => this.composer().id);
  protected readonly draftId = computed(() => this.composer().draft?.id || undefined);

  private readonly draftId$ = toObservable(this.draftId);

  readonly editor = new Editor({
    content: this.message(),
    extensions: [
      createExtensionKit(
        withBold(),
        withItalic(),
        withStrike(),
        withUnderline(),
        withFontFamily(),
        withFontSize(),
        withBulletList(),
        withOrderedList(),
        withLink(this.injector),
        withTable(),
        withTaskAutocomplete(this.injector),
        withPlaceholder(inject(TranslateService).instant('conversations-v4.composer.input.placeholder')),
      ),
    ],
    onCreate: () => {
      this.handleInitialFocus();
    },
  });

  formControl = new FormControl<string>(this.message());

  constructor() {
    this.initUpdateHandler();

    effect(() => {
      const message = this.message();

      untracked(() => {
        this.formControl.setValue(message, { emitEvent: false });
      });
    });
  }

  private initUpdateHandler(): void {
    this.draftId$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        switchMap(() =>
          // The reason we use an inner observable is to clear "distinctUntilChanged" history when the draft changes.
          //
          // Example:
          // 1. User types "a"
          // 2. Draft is saved
          // 3. User deletes the draft
          // 4. User types "a" again
          //
          // If we used operators operators below (specifically "distinctUntilChanged") in the outer stream,
          // the "distinctUntilChanged" would prevent the second draft from being saved as it would remember the previous "a", compare it to the new "a" and ignore it as it's the same.
          //
          // By using the inner observable, we ensure we recreate the inner stream below each time the draft id changes, which in turn clears the "distinctUntilChanged" history.
          //
          // The same applies to subject, quote, signature draft update handlers.
          this.formControl.valueChanges.pipe(
            distinctUntilChanged(),
            tap((message) => this.store.dispatch(new SetMessage(this.composerId(), message))),
            debounceTime(300),
            switchMap(() => this.store.dispatch(new SaveDraft(this.composerId()))),
          ),
        ),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }

  private handleInitialFocus(): void {
    const closestComposer = this.elementRef.nativeElement.closest('.composer') as HTMLElement;
    if (!closestComposer) return;

    // If there is a focused element inside the composer, do not focus the editor
    const focusedElement = closestComposer.querySelector(':focus');
    if (focusedElement) return;

    this.editor.commands.focus('end');
  }
}

@if (activeExternalAccount$ | async; as activeAccount) {
  <div class="composer-sender-picker">
    <cc-user-avatar
      class="composer-sender-picker__avatar"
      [src]="activeAccount.avatarUrl"
      [name]="activeAccount.name"
      [size]="24"
    />

    <div
      tabindex="0"
      #selectDropdownTrigger
      (click)="!disabled && (pickerDropdownVisible = !pickerDropdownVisible)"
      (keydown.enter)="!disabled && (pickerDropdownVisible = !pickerDropdownVisible)"
      [class.composer-sender-picker__select--active]="pickerDropdownVisible"
      [class.composer-sender-picker__select--disabled]="disabled"
      class="composer-sender-picker__select"
    >
      <div
        [ccTooltip]="activeAccount.name"
        [showOnlyOnOverflow]="true"
        class="composer-sender-picker__select-name"
        tooltipMaxWidth="auto"
      >
        {{ activeAccount.name }}
      </div>

      <div class="composer-sender-picker__select-email">
        <div class="composer-sender-picker__select-email-mark">&lt;</div>
        <div
          [ccTooltip]="activeAccount.email"
          [showOnlyOnOverflow]="true"
          class="composer-sender-picker__select-email-inner"
          tooltipMaxWidth="auto"
        >
          {{ activeAccount.email }}
        </div>
        <div class="composer-sender-picker__select-email-mark">&gt;</div>
      </div>

      @if (!disabled) {
        <i
          [class.icon-collapse]="pickerDropdownVisible"
          [class.icon-expand]="!pickerDropdownVisible"
          class="composer-sender-picker__select-toggle"
        ></i>
      }
    </div>

    <ng-template
      (detach)="pickerDropdownVisible = false"
      (overlayOutsideClick)="
        pickerDropdownVisible = getOverlayVisibilityAfterOutsideClick($event, selectDropdownTrigger)
      "
      [cdkConnectedOverlayOpen]="pickerDropdownVisible"
      [cdkConnectedOverlayOrigin]="selectDropdownTrigger"
      [cdkConnectedOverlayPositions]="pickerDropdownPositionStrategy"
      cdkConnectedOverlay
    >
      <cc-dropdown [width]="selectDropdownTrigger.offsetWidth + 'px'">
        @for (account of externalAccounts$ | async; track account.id) {
          <cc-dropdown-action
            (click)="selectSender(account)"
            [selected]="account.id === activeAccount.id"
            [selectable]="true"
          >
            <ng-container slot="title"> {{ account.name }}</ng-container>
            <ng-container slot="caption">
              {{ account.email }}
            </ng-container>
          </cc-dropdown-action>
        }
      </cc-dropdown>
    </ng-template>
  </div>
}
